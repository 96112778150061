$(function () {
	$("#logo_carousel").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 5650,

		responsive: [
			{
				breakpoint: 1570,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1050,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	});
});

$(function () {
	$("#logo_carousel2").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
		autoplay: true,
		autoplaySpeed: 5650,
		arrows: false,
		infinite: true,

		responsive: [
			{
				breakpoint: 1570,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1050,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	});
});

$(function () {
	$("#logo_carousel1").slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2650,
		dots: false,
		arrows: false,
		infinite: true,
		draggable: false,
		pauseOnHover: false,
		pauseOnFocus: false,
		swipeToSlide: false,
		touchMove: false,
		responsive: [
			{
				breakpoint: 1570,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1050,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
		],
	});
});

$(function () {
	$("#testimonial_carousel").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5300,
		draggable: false,
		pauseOnHover: true,
		cssEase: "ease-in-out",
		speed: 1000,
		dots: false,
		arrows: false,
		infinite: true,	
		variableWidth: false,
	});
});
AOS.init();

// $('.mirror').on('keyup', function() {
//     $('.mirror').val($(this).val());
// });
